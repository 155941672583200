import React from "react"
import PropTypes from "prop-types"
import shortid from "shortid"
import clsx from "clsx"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"
import {
  Avatar,
  Card,
  CardContent,
  Container,
  Grid,
  Icon,
  Typography,
} from "@material-ui/core"

import Breadcrumbs from "../components/Breadcrumbs"
import SEO from "../components/SEO"

const useStyles = makeStyles(theme => ({
  topWrapper: {
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 60px)",
    },
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 110px)",
    },
  },
  mainImage: {
    height: "100%",
  },
  topOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,.4)",
    top: 0,
    left: 0,
  },

  pageTitle: {
    fontSize: 48,
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
    color: theme.palette.common.white,
    textTransform: "uppercase",
    fontWeight: 700,
    textAlign: "center",
  },

  heading: {
    marginTop: theme.spacing(3),
    textTransform: "uppercase",
    fontWeight: 700,
    textAlign: "center",
    letterSpacing: -1,
    fontSize: 30,

    "& p": {
      margin: 0,
    },
  },
  slogan: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: 300,
  },
  avatar: {
    margin: "0 auto",
    background: "none",
    color: "#6dcff6",
    border: "1px dashed #ccc",
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  icon: {
    fontSize: 40,
    color: "#6dcff6",
    "&:before": {
      margin: 0,
    },
  },
  reasonTitle: {
    "& p": {
      margin: 0,
    },
  },
  container: {
    marginTop: 30,
  },
}))

const AboutUsPage = ({
  data: { aboutUsPage },
  pageContext: { slug, breadcrumbs, locale, alternateLanguages },
  location,
}) => {
  const classes = useStyles()

  const meta = {
    title: aboutUsPage.data.meta_title,
    description: aboutUsPage.data.meta_description,
    keywords: aboutUsPage.data.meta_keywords,
    author: "Turist in Transilvania",
  }

  const schema = aboutUsPage.data.schema_json.text || ""
  const ogp = aboutUsPage.data.ogp_tags || []

  ogp.push({
    property: "og:image",
    content: aboutUsPage.data.image.fluid.src,
  })
  ogp.push({
    property: "og:image:alt",
    content: aboutUsPage.data.image.alt,
  })
  ogp.push({
    property: "og:image:width",
    content: aboutUsPage.data.image.dimensions.width,
  })
  ogp.push({
    property: "og:image:height",
    content: aboutUsPage.data.image.dimensions.height,
  })
  ogp.push({
    property: "og:image:type",
    content: "image/jpeg",
  })

  const parsedSchema = JSON.parse(schema)

  return (
    <>
      <SEO
        schema={JSON.stringify(parsedSchema)}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
        location={location}
        alternateLanguages={alternateLanguages}
      />
      <div className={classes.topWrapper}>
        <Img
          className={classes.mainImage}
          alt={aboutUsPage.data.image.alt}
          fluid={aboutUsPage.data.image.fluid}
        />
        <Grid
          direction="row"
          justify="center"
          alignItems="center"
          container
          className={classes.topOverlay}
        >
          <Grid item xs={12}>
            <Typography component="h1" className={classes.pageTitle}>
              {aboutUsPage.data.title.text}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Container maxWidth="lg">
        <Typography
          component="h2"
          variant="h3"
          className={classes.heading}
          dangerouslySetInnerHTML={{
            __html: aboutUsPage.data.heading.html,
          }}
        />

        <Typography component="h3" className={classes.slogan}>
          {aboutUsPage.data.slogan.text}
        </Typography>
      </Container>
      <br />
      <br />
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {aboutUsPage.data.reasons.map(reason => (
            <Grid
              item
              className={classes.summaryBox}
              xs={12}
              md={6}
              key={shortid.generate()}
            >
              <Card className={classes.card} variant="outlined">
                <CardContent>
                  <Grid
                    container
                    spacing={2}
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={3}>
                      <Avatar className={classes.avatar}>
                        <Icon className={clsx(reason.icon, classes.icon)} />
                      </Avatar>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Typography
                        gutterBottom
                        component="h4"
                        variant="h5"
                        dangerouslySetInnerHTML={{
                          __html: reason.reason_title.html,
                        }}
                        className={classes.reasonTitle}
                      />
                      <Typography>{reason.reason_description.text}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  )
}

export default AboutUsPage

export const query = graphql`
  query AboutUsPageQuery($slug: String!, $locale: String!) {
    aboutUsPage: prismicAboutUsPage(uid: { eq: $slug }, lang: { eq: $locale }) {
      data {
        ogp_tags {
          content
          property
        }
        schema_json {
          text
        }
        meta_title
        meta_description
        meta_keywords
        image {
          alt
          dimensions {
            height
            width
          }
          fluid(maxWidth: 1980, maxHeight: 1070) {
            ...GatsbyPrismicImageFluid
          }
        }
        slogan {
          text
        }
        title {
          text
        }
        heading {
          html
        }
        reasons {
          icon
          reason_description {
            text
          }
          reason_title {
            html
          }
        }
      }
    }
  }
`

AboutUsPage.propTypes = {
  data: PropTypes.shape({
    aboutUsPage: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
    alternateLanguages: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
